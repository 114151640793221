import React from "react";
import Divider from "../Inputs/Divider";
import './ImageDetailsAlternatingList.css';
import { ImageListItem } from "../../data/SiteData";

export interface ImageDetailsAlternatingListProps {
    Title: string,
    Items: ImageListItem[]
}

const ImageDetailsAlternatingList = (props: ImageDetailsAlternatingListProps) => {
    let itemCount = 0;
    
    return (
        <div className="image-details-alternating-list">
            <h3>{props.Title}</h3>
            <Divider />
            <div className="d-flex flex-column">
                {props.Items.map(item => {
                    itemCount++;
                    if (itemCount % 2 === 0) {
                        return (
                            <>
                            <div className="d-flex flex-lg-row flex-column">
                                <div className="centered d-lg-none">
                                    <h5 className="centered">{item.Title}</h5>
                                </div>
                                <div className="centered">
                                    <img src={item.Source} alt={item.Title} className="centered" loading="lazy" />
                                </div>
                                <div className="centered d-none d-lg-block">
                                    <h5 className="centered">{item.Title}</h5>
                                </div>
                            </div>
                            </>
                        );
                    }
                    else {
                        return (
                            <>
                            <div className="d-flex flex-lg-row flex-column">
                                <div className="centered">
                                    <h5 className="centered">{item.Title}</h5>
                                </div>
                                <div className="centered">
                                    <img src={item.Source} alt={item.Title} className="centered" loading="lazy" />
                                </div>
                            </div>
                            </>
                        );
                    }
                })}
            </div>
        </div>
    );
}

export default ImageDetailsAlternatingList;