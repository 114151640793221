import React from "react";
import { useNavigate } from "react-router-dom";

const LinkButton = (props: any) => {
    let navigate = useNavigate();
    return (
            <button className="btn btn-success mt-5" type="submit" onClick={() => navigate(props.href)}>
                {props.text}
            </button>
    );
};

export default LinkButton;