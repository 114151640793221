import React from "react";
import Divider from "../Inputs/Divider";
import './ImageDetailsListSmall.css';
import { ImageListItem } from "../../data/SiteData";

export interface ImageDetailsListSmallProps {
    Title: string,
    Items: ImageListItem[]
}

const ImageDetailsListSmall = (props: ImageDetailsListSmallProps) => {
    return (
        <>
        <div className="image-details-list-small">
            <h3>{props.Title}</h3>
            <Divider />
            <div className="d-flex flex-column">
                <div className="row">
                    {props.Items.map(item => {
                        return (
                            <>
                            <div className="col-lg-3 col-sm-4 col-6 centered">
                                <img src={item.Source} alt={item.Title} className="centered" loading="lazy" />
                                <h5 className="centered">{item.Title}</h5>
                            </div>
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
        </>
    );
}

export default ImageDetailsListSmall;