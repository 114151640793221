import React from "react";
import Divider from "../Inputs/Divider";
import { JsxElement } from "typescript";
import './AboutAlternatingList.css';
import { SummaryListItem } from "../../data/SiteData";

interface AboutAlternatingListProps {
    Title: string,
    Items: SummaryListItem[]
}

const AboutAlternatingList = (props: AboutAlternatingListProps) => {
    let itemCount = 0;
    return (
        <div className="about-alternating-list">
            <h3>{props.Title}</h3>
            <Divider />
            <div className="d-flex flex-column">
                <div className="row">
                    {props.Items.map(item => {
                        itemCount++;
                        if (itemCount % 2 === 0) {
                            return (
                                <>
                                <div className="col-lg-4 col-12 centered pt-lg-5 d-lg-none">
                                    <h1>{item.Title}</h1>
                                </div>
                                <div className="col-lg-8 col-12 p-5 mb-5 list-item">
                                    <div>
                                        {item.Details}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 centered pt-lg-5 d-none d-lg-block">
                                    <h1>{item.Title}</h1>
                                </div>
                                </>
                            );
                        }
                        else {
                            return (
                                <>
                                <div className="col-lg-4 col-12 centered pt-lg-5">
                                    <h1>{item.Title}</h1>
                                </div>
                                <div className="col-lg-8 col-12 p-5 mb-5 list-item">
                                    <div>
                                        {item.Details}
                                    </div>
                                </div>
                                </>
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );
}

export default AboutAlternatingList;