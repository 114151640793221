import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import { Offcanvas, Nav, Navbar } from "react-bootstrap";
import { SiteLinks, StringData } from "../../data/SiteData";
import './Header.css';

const Header = () => {
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    console.log(dimensions);
    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
      }
    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
    }, []);
    
    return (
        <div className="App-header">
            <h3><a href="/">{StringData.HeaderTitle}</a></h3>
            <Navbar expand="lg" className="hamburger" bg="dark" data-bs-theme="dark">
                <Container fluid>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${false}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
                        placement="end">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${false}`}>
                                {StringData.HeaderTitle}
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                {SiteLinks.map(link => {
                                    return (
                                        <li key={link.id}>
                                            <Nav.Link href={link.path}>
                                                {link.text}
                                            </Nav.Link>
                                        </li>
                                    );
                                })}
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </div>
    );
}

export default Header;