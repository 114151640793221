import React from "react";
import AboutList from "../components/Modules/AboutAlternatingList";
import { AboutUsItems, StringData } from "../data/SiteData";

const About = (props: any) => {
    
    return (
        <>
        <div className="page_header"></div>
        <AboutList Title={StringData.AboutUsTitle} Items={AboutUsItems} />
        </>
    );
}

export default About;