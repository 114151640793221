import React from "react";
import Divider from "../Inputs/Divider";
import Button from "../Inputs/Button";
import './CaptionedText.css';
import { SummaryImageListItem } from "../../data/SiteData";
import { useNavigate } from "react-router-dom";

export interface CaptionedTextProps {
    Title: string
}

const ImageDetailsAlternatingListSmall = (props: CaptionedTextProps) => {
    let navigate = useNavigate();
    let itemCount = 0;
    
    return (
        <div className="captioned-text">
            <div className="caption">
                <h3 className="about-title">{props.Title}</h3>
            </div>
        </div>
    );
}

export default ImageDetailsAlternatingListSmall;