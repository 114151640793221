import React from "react";
import AboutList from "../components/Modules/DetailsListSmall";
import { StringData, VisionItems, VisionItems2 } from "../data/SiteData";

const Vision = (props: any) => {
    
    return (
        <>
        <div className="page_header"></div>
        <AboutList Title={StringData.VisionTitle} Items={VisionItems} />
        </>
    );
}

export default Vision;