import React, { useState } from "react";

const ButtonAccordion = (props: any) => {
    let [state, setState] = useState(props.OpenByDefault);

    function Open() {
        setState(!state);
    }

    return (
        <div className="mt-3">
            <div className={`collapsed ${state ? 'show' : ''}`}>
                {props.Body}
            </div>
            <button className="btn btn-success mt-5" type="submit" onClick={Open}>
                {state ? "Less" : "More"}
            </button>
        </div>
    );
};

export default ButtonAccordion;