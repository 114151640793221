import React from "react";
import ProductSummaryList from "../components/Modules/DetailsListSmall";
import Welcome from "../components/Modules/WelcomeSplitSmall";
import AboutSummary from "../components/Modules/CaptionedText";
import { CurrentSites, UpcomingSites, StringData, TheVisionBlock, OtherSites } from "../data/SiteData";

const Home = (props: any) => {
    return (
        <>
        <Welcome Title={StringData.WelcomeTitle} Message={StringData.WelcomeMessage} />
        <AboutSummary Title={StringData.AboutTitle} />
        <ProductSummaryList Title="The Vision" Items={TheVisionBlock} />
        <ProductSummaryList Title="Existing Software" Items={CurrentSites} SmallItems={OtherSites} />
        <ProductSummaryList Title="In Development" Items={UpcomingSites} />
        </>
    );
}

export default Home;