import './DetailsListSmall.css';
import { SummaryImageListItem } from "../../data/SiteData";
import { useNavigate } from "react-router-dom";
import Button from '../Inputs/Button';

export interface DetailsListSmallProps {
    Title: string,
    Items: SummaryImageListItem[],
    SmallItems?: SummaryImageListItem[]
}

const ImageDetailsAlternatingListSmall = (props: DetailsListSmallProps) => {
    let navigate = useNavigate();
    let itemCount = 0;
    
    return (
        <div className="details-list-small">
            <div className="d-flex flex-column">
                <h1 className="centered about-title">{props.Title}</h1>
                {props.Items.map(item => {
                    return (
                        <>
                        <div className="row">
                            <div className=" col-12 description">
                                <div className="list-item">
                                    {item.Source ?
                                    <div className="centered"><img src={item.Source} alt="Item Logo" /></div> :
                                    <h1 className='centered'>{item.Title}</h1>}
                                    {item.Details}
                                </div>
                            </div>
                        </div>
                        </>
                    );
                })}
                {props.SmallItems && <div className="row centered justify-content-evenly">
                    <h3 className="centered" style={{marginBottom: 20}}>Software Developed For Church Members</h3>
                    {props.SmallItems.map(item => {
                        return (
                            <div className="col-10 col-lg-5 description-small">
                                <div className="list-item">
                                    <div className="centered img-div"><img src={item.Source} alt="Item Logo" /><br />{item.Details}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>}
            </div>
        </div>
    );
}

export default ImageDetailsAlternatingListSmall;