import React from "react";
import Button from "../Inputs/Button";
import Divider from "../Inputs/Divider";
import { useNavigate } from "react-router-dom";
import './WelcomeSplitSmall.css';

interface WelcomeSplitSmallProps {
    Title: string,
    Message: string
}

const WelcomeSplitSmall = (props: WelcomeSplitSmallProps) => {
    let navigate = useNavigate();
    
    return (
        <div className="welcome-split-small">
            <div className="welcome-split-small-container">
            <img src={require("../../images/freelife_applications_blue_back.png")} alt="Light at the end of the tunnel" />
            <div className="overlay d-flex flex-column justify-content-center align-items-center">
                <h1>{props.Title}</h1>
                <Divider />
                <h5>{props.Message}</h5>
            </div>
            </div>
        </div>
    );
}

export default WelcomeSplitSmall;