import React from "react";
import AboutList from "../components/Modules/AboutAlternatingList";
import { AboutUsItems, StringData } from "../data/SiteData";

const SGSupport = (props: any) => {
    
    return (
        <>
        <div className="page_header"></div>
        <div className="centered px-5" style={{width:'80%'}}>
            <h1 className="mt-5">ServeGuardian Support</h1>
            For application support, please contact us at freelifeapplications@gmail.com.
        </div>
        </>
    );
}

export default SGSupport;