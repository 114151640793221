import React from "react";
import ProductDetailsList from "../components/Modules/ImageDetailsAlternatingList";
import ProductSummaryList from "../components/Modules/ImageDetailsListSmall";
import { AvailableProducts, SoonToComeProducts } from "../data/SiteData";

const Products = (props: any) => {
   
    return (
        <>
        <div className="page_header"></div>
        <ProductDetailsList Title="Available Products" Items={AvailableProducts} />
        <ProductSummaryList Title="Soon to Come" Items={SoonToComeProducts} />
        </>
    );
}

export default Products;