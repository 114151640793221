import React from "react";
import { Link } from "react-router-dom";
import { StringData } from "../../data/SiteData";
import './Footer.css';

const Footer = () => {
    return (
        <div className="App-footer">
            <h5>{StringData.FooterTitle}</h5>
            <Link to='#' onClick={(e) => {
                window.location.href = `tel:${StringData.PhoneNumber.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')}`;
                e.preventDefault();
            }}>
                {StringData.PhoneNumber}
            </Link>
            <Link to='#' onClick={(e) => {
                window.location.href = `mailto:${StringData.Email}`;
                e.preventDefault();
            }}>
                {StringData.Email}
            </Link>
            {StringData.Copywright}
        </div>
    );
}

export default Footer;