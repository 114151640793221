import React from "react";
import AboutList from "../components/Modules/AboutAlternatingList";
import { AboutUsItems, StringData } from "../data/SiteData";

const About = (props: any) => {
    
    return (
        <>
        <div className="page_header"></div>
        <div className="centered px-5" style={{width:'80%'}}>
            <h1 className="mt-5">ServeGuardian Account Deletion</h1>
            To request deletion of your ServeGuardian account, please contact us at freelifeapplications@gmail.com. For legal purposes, your email address and disclaimer agreement status will remain archived in our database for up to 12 months after account deletion.
        </div>
        </>
    );
}

export default About;