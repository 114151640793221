import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserDataItem {
    familyId: number
}

interface UserDataState {
    value: UserDataItem
}

const initialState: UserDataState = {
    value: {
        familyId: 0
    }
};

export const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        set: (state = initialState, action: PayloadAction<UserDataItem>) => {
            state.value = action.payload;
        },
        remove: (state = initialState, action: PayloadAction<number>) => {
            state.value = {
                familyId: 0
            };
        },
        updateUserData: (state = initialState, action: PayloadAction<UserDataItem>) => {
            state.value = {...action.payload};
        }
    }
});

export const { set, remove, updateUserData } = userDataSlice.actions;

export default userDataSlice.reducer;