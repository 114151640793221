import React from "react";

const NotMatch = () => {
    return (
        <div>
            <h3>No match for this page</h3>
        </div>
    );
};

export default NotMatch;