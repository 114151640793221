import Button from "../components/Inputs/Button";
import ButtonAccordion from "../components/Inputs/ButtonAccordion";
import LinkButton from "../components/Inputs/LinkButton";

export interface ImageListItem {
    Title: string,
    Source: any
}

export interface SummaryListItem {
    Title: string,
    Details: JSX.Element
}

export interface SummaryImageListItem {
    Title: string,
    Details: JSX.Element,
    Source: any,
    Link?: string,
    ButtonText?: string
}

export interface SummaryImageDetailListItem {
    Title: string,
    Details: string,
    Source: any
}

export const StringData = {
    HeaderTitle: "FreeLife Applications, LLC",
    WelcomeTitle: "Building Your Vision",
    WelcomeMessage: "Our vision is to make your vision reality",
    WelcomeButton: "Contact",
    AboutTitle: "At FreeLife Applications, LLC, we strive to assess the vision of ministries and find and develop tools and processes to help improve the effectiveness of that vision.",
    AboutMessage: "",
    AboutButton: "About",
    AboutUsTitle: "About Us",
    VisionTitle: "Our Vision",
    VisionTitle2: "How our apps fit our vision",
    ContactUsTitle: "",
    ContactUsHead: "Contact Us",
    ContactUsMessage: "To find out more information about FreeLife Applications, LLC or get a quote for a custom website, call (469) 212-5465 or email freelifeapplications@gmail.com",
    ContactUsHead2: "",
    ContactUsMessage2: "FreeLife Applications, LLC is a for profit company. While we are happy to take donations to help us continue to do this work full time, any money donated to FreeLife Applications, LLC will not be tax deductible.",
    FooterTitle: "FreeLife Applications, LLC",
    PhoneNumber: "",
    Email: "freelifeapplications@gmail.com",
    Copywright: "Copyright © 2023 FreeLife Applications - All Rights Reserved."
}

export const SiteLinks = [
    {
        id: 4,
        path: "/",
        text: "Home"
    },
    {
        id: 5,
        path: "/about",
        text: "About"
    },
    {
        id: 2,
        path: "/contact",
        text: "Contact Us"
    },
    {
        id: 1,
        path: "https://sheklbudget.com",
        text: "Shekl"
    }
];

export const AvailableProducts: ImageListItem[] = [
    {
        Title: "Product 1",
        Source: require("../images/code.jpg"),
    },
    {
        Title: "Product 2",
        Source: require("../images/code.jpg"),
    },
    {
        Title: "Product 3",
        Source: require("../images/code.jpg"),
    },
    {
        Title: "Product 4",
        Source: require("../images/code.jpg"),
    }
];

export const SoonToComeProducts: ImageListItem[] = [
    {
        Title: "Item1",
        Source: require("../images/code.jpg"),
    },
    {
        Title: "Item2",
        Source: require("../images/code.jpg"),
    },
    {
        Title: "Item3",
        Source: require("../images/code.jpg"),
    }
];

export const WhatWeOffer: ImageListItem[] = [
    {
        Title: "Item 1",
        Source: require("../images/code.jpg"),
    },
    {
        Title: "Item 2",
        Source: require("../images/code.jpg"),
    },
    {
        Title: "Item 3",
        Source: require("../images/code.jpg"),
    }
];

export const AboutUsItems: SummaryListItem[] = [
    {
        Title: "Who We Are",
        Details: (<>
            FreeLife Applications is currently a small team (Shanna and Joshua Blaylock) based out of Mount Vernon, TX. We do what we do because we believe we were called to it. We believe God has a plan to impact our communities and the church as we serve each other.<br /><br />
            For our part, we want to offer knowledge and experience gained from over 10 years in the software industry to build a better, more supportive church community. To accomplish this, we are building software tools for church administration and members and tools to support member-run ministries and businesses. We are actively seeking God's direction on how best to serve and support our local community with these tools. We have sought the wisdom of successful church leaders with years of experience to design these tools based on their church needs.<br /><br />
            This mindset of sharing each other's vision and serving each other is at the center of what we do and how we do it. We believe your business is your ministry, and success is shared by recognizing each other's strengths and using those strengths to grow personally and professionally. We put ourselves into our work and strive for excellence, but the strength we lean on is not our own. We trust God to make Freelife Applications what it needs to be, through his plan and timing.</>)
    },
    {
        Title: "How We Got Here",
        Details: (
            <>
                It first began in 2021 with a Vision. Shanna had 2 dreams in a row. The first involved us finding people in need and helping them. The dream ended with this word, “They are not going to ask. You are going to need to find them.” The second dream was to start a new software company. We were the team in this dream working on something together. We believe the second dream was meant to be an answer to the first. God showed us the need, and then the answer.<br /><br />
                In 2022, we began what we believe is the core of our vision, Seeds & Needs. The start of this project was daunting. We only had a piece of the puzzle. We realized there was an abundance of questions that needed answers if we were to design Seeds and Needs properly. We didn't try to make these decisions alone. We went to our local church leaders and asked for their guidance. We wanted their knowledge, experience and their spiritual covering on this project.<br /><br />
                What we found by conversing with these church leaders is how deep the need goes for useful software tools. We found the current software pool most churches have to work with is disparate and disconnected. They are forced to settle for multiple applications that don't cover all their needs. This has  the church staff groaning, “Not another app! Not another log in to keep up with!”  The administrative load is high because of the variety of services that churches have to use. Unfortunately, these services often don't communicate with one another. What's more, many of the services are not designed to be used by churches in the first place! This requires an unnecessary amount of work to twist the corporate based applications into something resembling what the church needs.<br /><br />
                At the same time, we discovered that church members were also struggling through a number of the same barriers of communication. These same disconnected software applications the church administration struggled to use, the congregation also found to be complex, and ineffective. We began to ask ourselves, could we also make something for the members that would help them better keep up with what was going on at church?<br /><br />
                As an attempt to solve these issues, so that church staff can spend less time with business-like administrative tasks and members can have more accessible ways of interacting with their church, MyHub was born in mid 2023. We are currently expanding based on the need at hand. We want what software is made to be made well, and with the needs of the church in mind.<br /><br />
                Also, at this time, in order to help fund the development of these applications and to support local ministries, we are offering web design services to individuals and businesses in the church.
            </>)
    },
    {
        Title: "Where We're Going",
        Details: (<>
            MyHub and Seeds & Needs are being built first for our home church, The Way Bible Church in Sulphur Springs, TX. We are currently working hard to develop solutions catered to the needs of a fast-growing church. As we continue to build out the software, we are also focused on designing it in such a way that we will be able to package it for other churches with minimal setup necessary. We expect to continue improving this software for years to come, developing new solutions for the different needs of many church congregations. With this in mind, we want to design our software so churches can connect with each other and communicate their needs easily and effectively, in the hopes that we can support each church's vision and truly become more like the vision God had for us.
        </>)
    },
    {
        Title: "How You Can Help",
        Details: (<>
            FreeLife Applications is a for profit ministry. But our focus is ministry first, profit second. That being said, we cannot continue our work without provision to do so. There are two ways you can support our ministry/business. You can either become a customer or donate to our cause to make software for church communities.<br /><br />
            For the Customer: We offer web design services for a reasonable price. If you are not computer savy, that's ok. We can help you with every step of the process. We can help you envision your website design and write page content, and we can guide you through the entire process of setting up your site. For those who are more independent and proficient we will soon have an easy to use interface for you to design your own website. With several design templates to choose from, you can really make the website you own. We will also have options for ecommerce available to add to your web site for an extra fee. Just tells us what you are needing and let us help you how you would like to be helped.<br /><br />
            For the Donors: We began this ministry on faith. We offered something free before we asked for anything in return. But the bible says, we have not because we ask not, so we are going to ask, would you be willing to help us financially? The money you donate goes directly to paying our bills, paying for our food and seeing to our personal needs. This support helps us continue to do this task we feel called to do. The money you give us, allows us to continue our work. We are a for profit ministry, and your donation is not tax deductible, but it is very much appreciated.
            <div className="centered"><LinkButton text="Support FLA" href="/contact" /></div>
        </>)
    }
];

export const VisionItems: SummaryImageListItem[] = [
    {
        Title: "The Church",
        Details: (
            <div className="description">
                1 Corinthians 12 describes the Church as “the body of Christ.” Jesus says that the second great commandment, after loving God, is to love your neighbor as yourself. We believe this means that you should see the needs of others, particularly within the body of Christ, as though they were your needs. Their desires, those from God, should be your desires. The body of Christ should see itself as one and learn to function as such. They say you can't properly love others unless you love yourself. How then can the body of Christ love the world if it doesn't love itself? They say you can't properly take care of others if you don't take care of yourself. How then can the body of Christ effectively lift up those in need outside of the Church if we don't first lift each other up?<br /><br />
                We believe that the church is called to support one another, through whatever means with which God has blessed us. This means people, ministries, and churches all freely offering direct support to one another.
            </div>),
        Source: ""
    },
    {
        Title: "One Body Network",
        Details: (
            <div className="description">
                With the One Body Network we are developing a foundation for a self-supporting Church through a community network that connects individuals, ministries, and churches to one another.
                <div className="centered"><ButtonAccordion Body={<div style={{textAlign: 'left'}}>
                    One Body Network represents the core of our vision, as it is a tool specifically designed to facilitate the body's ability to support itself. Through various applications and networking tools we are building a web of interconnected churches, ministries and individuals who can support one another's vision.<br /><br />
                    Seeds & Needs, for example, is an application that will allow individual members the ability to find other members in need and support them. This can be done through various methods, such as:
                    <ul>
                        <li>Posting items you have available to give</li>
                        <li>Searching through a bulletin board of needs to find ones you can and/or feel led to fill</li>
                        <li>Allowing church organizations to create giving campaigns that you can choose to both benefit from and give to</li>
                        <li>Allowing people to register as available for a variety of services, from car maintenance to plumbing to temporary housing</li>
                    </ul>
                    <br />
                    Not only do we believe in empowering people to support one another directly, we also believe that churches and ministries should be supporting one another directly. The Church has spent too long fostering a divided body through donominations and competitiveness. No part of the body can stand on its own, and our vision should reach beyond our ministry's capacity to accomplish it.<br />
                    One Body Network will be working with churches and ministries to understand how their vision fits into a broader vision for their area and the Church as a whole. Through a strong network, we will help them connect to those who can fill in the gaps in their vision as well as see where they are called to fill in the gaps for others.
                </div>} /></div>
            </div>),
        Source: ""
    },
    {
        Title: "One Body Foundation",
        Details: (
            <div className="description">
                Through One Body Foundation we are working with other companies, churches, and individuals to create affordable and accessible technology standards for churches and ministries through the development of open-source software and integration with existing tools.
                <div className="centered"><ButtonAccordion Body={<div style={{textAlign: 'left'}}>
                    One Body Foundation is built around support over competition. There are a number of nonprofit and for-profit companies that are making software tools designed specifically for churches and ministries. Our goal is to bring all of these companies together to create a different kind of marketplace - one that is based on need rather than greed. We support competition, but believe that it should be done through fair value and not undercutting competitors or locking up data.<br /><br />
                    The tech industry has various standards and organizations dedicated to constantly improving those standard. The problem is: A) Church-centered software often doesn't even meet those standards, and B) Those standards are designed to support companies whose goals don't always line up with those we should be pursuing. We believe the tech industry inside the Church needs to come together and design standards aimed at improving the Body's ability to fullfill God's purpose through affordable, accessible technology that meets and potentially exceeds the quality standards held by everyone else.<br /><br />
                    The primary product developed by One Body Foundation is MyHUB, which is a free-to-use, open-source application that is designed to connect to various services available in the marketplace and bring the data into a centralized location for the user.
                    MyHUB is a software tool designed to support church organizations (i.e. churches) by addressing 3 critical barriers:
                    <ul>
                    	<li><b>Time and money:</b> One of the largest hurdles for churches we've found is the current software tools available, which generally offer some combination of being overpriced, overly complicated, and not designed for churches. Most of these tools are disconnected, requiring administrators to keep track of a large number of websites and re-enter the same data into multiple systems. With MyHUB, we seek to integrate as much of this as possible into a single, open-source location with a variety of tools that can communicate with one another.</li>
                    	<li><b>Communication between churches and people:</b> We have discovered several areas in which churches struggle to communicate with their congregations. We intend to offer a variety of tools to help the church assess the needs of the congregation, entice the congregation to share their needs, and communicate those needs back to the congregation along with other information, such as daily scriptures and scheduled events.</li>
                    	<li><b>Communication between people:</b> MyHUB will integrate with various tools developed by One Body Network as well as any other partner company with tools designed to connect the Body together.</li>
                    </ul>
                    Any partner company with tools they would like to integrate into MyHUB is welcome to do so. They can dedicate developers themselves to that integration or open up their API so that others can work on it.
                    MyHUB has tools built into it to help church organizations assess the needs of their congregations and the effectiveness of various tasks such as giving campaigns, attempts to increase web traffic and childcare at events.<br />
                    Our end goal with MyHUB is to facilitate the body's ability to communicate with one other and support one another. While we believe the administrative tools are necessary to build a proper foundation, allowing the church organization the tools it needs to manage the system, MyHUB is primarily designed as a digital home for church members.
                </div>} /></div>
            </div>),
        Source: ""
    },
    {
        Title: "FreeLife Applications",
        Details: (
            <div className="description">
                At FreeLife Applications we assess the vision of ministries and find and develop tools and processes to help improve the effectiveness of that vision.
                <div className="centered"><ButtonAccordion Body={<div style={{textAlign: 'left'}}>
                    FreeLife Applications was created in service of our vision for the church, One Body Network and One Body Foundation. We seek to help churches, ministries and people understand their vision and overcome any barriers that hinder their ability to fullfill their calling.<br /><br />
                    Generally, this includes working directly with ministries to learn what they are trying to accomplish, what steps they are taking and what challenges they are facing. We then assess the effectiveness of their processes and tools and attempt to determine what is needed to be more effective. This may lead to us advising new processes, finding existing tools that can help or developing tools to support their need. We then help them implement any suggestions they choose to implement and observe the effectiveness of that, offering additional suggestions if necessary.
                </div>} /></div>
            </div>),
        Source: ""
    }
];

export const VisionItems2: SummaryListItem[] = [
    {
        Title: "MyHUB",
        Details: (<>
            MyHUB is a software tool designed to support church organizations (i.e. churches) by addressing 3 critical barriers:<br /><br />
            <div className="mx-lg-5">
            •	<b>Time and money:</b> One of the largest hurdles for churches we've found is the current software tools available, which generally offer some combination of being overpriced, overly complicated, and not designed for churches. Most of these tools are disconnected, requiring administrators to keep track of a large number of websites and re-enter the same data into multiple systems. With MyHUB, we seek to integrate as much of this as possible into a single, open-source location with a variety of tools that can communicate with one another. As an open-source project, there will be opportunities for churches to implement the software with little to no cost, and other tool developers can contribute to the software by offering integration into the system.<br />
            •	<b>Communication between churches and people:</b> We have discovered several areas in which churches struggle to communicate with their congregations. We intend to offer a variety of tools to help the church assess the needs of the congregation, entice the congregation to share their needs, and communicate those needs back to the congregation along with other information, such as daily scriptures and scheduled events.<br />
            •	<b>Communication between people:</b> Through a variety of avenues including Seeds & Needs, small group communication and a business directory, we hope to create a place where we can help the body create a proper support network.<br /><br />
            </div>
            MyHUB has tools built into it to help church organizations assess the needs of their congregations and the effectiveness of various tasks such as giving campaigns, attempts to increase web traffic and childcare at events.<br /><br />
            Our end goal in MyHUB is to facilitate the body's ability to communicate with one other and support one another. While we believe the administrative tools are necessary to build a proper foundation, allowing the church organization the tools it needs to manage the system, MyHUB is primarily designed as a digital home for church members.<br /><br />
            Along with this tool we will offer extensive training on how to effectively fulfill their vision for their congregation in an increasingly digital age while not leaving older generations behind. This process will be ongoing and will likely evolve over time. Our goal is to create a network of those using the software to share what they learn from using it to help improve effectiveness for everyone.
        </>)
    },
    {
        Title: "Seeds & Needs",
        Details: (<>
            Seeds & Needs is a significant piece of our vision, as it is a tool specifically designed to facilitate the body's ability to support itself. It is not only a part of MyHUB - it is the primary reason why MyHUB exists. Seeds & Needs is meant to allow individual members the ability to find other members in need and support them. This includes options such as posting items you have available to give, searching through a bulletin board of needs to find ones you can and/or feel led to fill, allowing church organizations to create giving campaigns that you can choose to both benefit from and give to, and allowing people to register as available for a variety of services, from car maintenance to plumbing to temporary housing.<br /><br />
            Much like MyHUB, Seeds & Needs will require training, not so much on how to use the software but on how to be effective when using it. The software will be managed by church organizations. Those churches should have funds dedicated to addressing needs that go unmet. They will need to define procedures for security, and there are regular tasks that should be performed to keep members actively involved and make sure that as many needs are being met as possible.
        </>)
    },
    {
        Title: "Shekl Church",
        Details: (<>
            Shekl is the first piece built for MyHUB, but it's also its own application. Shekl is a budgeting app currently designed specifically for church organizations. It allows churches to set budgets and assign them to staff members. Staff may then submit expenses against those budgets and track the progress of their budgets. We will likely also create versions of the application for personal budgeting as well as other ministries with different budgeting needs.
        </>)
    }
];

export const CurrentSites: SummaryImageListItem[] = [
    {
        Title: "Shekl",
        Details: (
        <>
            <div className="d-flex">
                <div className="d-block">
                    <div className="shekl align-self-center"><img src={require("../images/sheklshare.png")} /></div>
                    <div className="description">
                        Shekl is currently available at <a href="https://sheklbudget.com">sheklbudget.com</a><br /><br />
                        Shekl Church Budgetting is a budgetting and expensing software designed specifically specifically for the needs of churches.<br /><br />
                        We designed Shekl to be simple and intuitive while giving you the freedom to customize it to fit your needs. Administrators can set budgets and make adjustments as needs change, while other users can take advantage of the clean interface to quickly upload expenses, check remaining budget and move on with more important things.
                    </div>
                    </div>
            </div>
        </>),
        Source: require("../images/shekllogo.png")
    }
];

export const TheVisionBlock: SummaryImageListItem[] = [
    {
        Title: "",
        Details: (
        <>
            <div className="d-flex">
                <div className="d-block">
                    <div className="description">
                        FreeLife Applications, LLC was created as part of a three part initiative designed to fullfill a singular vision - to foster a unified Body of Christ that properly supports itself.
                        <div className="centered"><LinkButton text="Learn More" href="/vision" /></div>
                    </div>
                </div>
            </div>
        </>),
        Source: ""
    }
];

export const UpcomingSites: SummaryImageListItem[] = [
    {
        Title: "MyHub",
        Details: (
            <>
            <div className="d-flex">
                <div className="d-block">
                    <div className="shekl align-self-center"><img src={require("../images/myhub.png")} /></div>
                    <div className="description">
                    <h3>
                        <div>(Comming Soon)</div>
                    </h3>
                    We live in a world that is run by software. Everything is online, and everyone expects to be able to access what they need with the click of a mouse.<br /><br />
                    While many of these tools make our jobs easier, there are just too many to keep track of! That's a problem when you have to remember which ones you are using, what you are using them for, and where to access them.<br /><br />
                    And worse, most of these tools aren't designed for churches.<br /><br />
                    MyHub is designed to help you bring all of these tasks into a single location, simplifying your workload and saving you time. We offer solutions for many of the tasks you need to perform every day, and if you don't want to switch solutions, for some of the tools you currently enjoy, that's ok! We are continually looking for opportunities to partner with those companies you love. We want to allow those tools you currently use and appreciate to be integrated into my hub.<br /><br />
                    But MyHub isn't just about easing the load on administrators, it's also about connecting to your members. In fact, we're hoping that by simplifying all that boring administrative stuff, that you will have that extra time to see to your members' needs. We offer a variety of tools to allow you to connect directly to your congregation and help them stay connected as well. With personal calendar integration, your members can opt to plan their lives around their church community and so much more!
                    </div>
                </div>
            </div>
        </>),
        Source: require("../images/myhublogo.png")
    },
    {
        Title: "Seeds & Needs",
        Details: (
            <>
            <div className="d-flex">
            <div className="d-block">
                    <div className="shekl align-self-center"><img src={require("../images/seed.jpg")} /></div>
                    <div className="description">
                    <h3>
                        <div>(Comming Soon)</div>
                    </h3>
                    Seeds & Needs represents the core vision of FreeLife Applications, LLC<br /><br />
                    The Seeds & Needs app is a church-run, member-powered application, designed to allow individuals within the church body to directly support one another.<br /><br />
                    The process is simple: Users can post items or services that they are in need of (Needs) as well as items that they have available to bless others with (Seeds). If a posted Need matches a posted Seed, the users will be prompted to set up a time to meet. Matches are created automatically based on a few factors, such as quality of the match and age of the posts.<br /><br />
                    There are also a variety of other giving options available in the app, such as church-led giving campaigns.
                    </div>
                </div>
            </div>
        </>),
        Source: require("../images/seedsandneeds.png")
    }
];

export const OtherSites: SummaryImageListItem[] = [
    {
        Title: "The Body of Christ Ministries Global, Inc.",
        Details: (<a href="https://thebodyofchristministriesglobal.org">View Website</a>),
        Source: require("../images/tbocg.png")
    },
    {
        Title: "ServeGuardian",
        Details: (<>Available on <a href="https://apps.apple.com/us/app/serveguardian/id6467407335">iOS</a> and <a href="https://play.google.com/store/apps/details?id=com.FreeLifeApplications.ServeGuardian">Android</a></>),
        Source: require("../images/serveguardian.png")
    }
];
