import React from "react";
import Divider from "../components/Inputs/Divider";
import { StringData } from "../data/SiteData";
import './Contact.css';

const Contact = (props: any) => {
    return (
        <>
        <div className="page_header"></div>
        <div className="contact-us">
            <h3>{StringData.ContactUsTitle}</h3>
            <Divider />
            <div className="centered mt-5 mb-5">
                <h3>{StringData.ContactUsHead}</h3>
                {StringData.ContactUsMessage}
            </div>
            <h3 className="mt-5 centered">Support FLA</h3>
            <div className="centered mb-3">
                <h3>{StringData.ContactUsHead2}</h3>
                {StringData.ContactUsMessage2}
            </div>
            <div dangerouslySetInnerHTML={{ __html: '<givebutter-widget id="Lqzb2g" align="center"></givebutter-widget>' }}></div>
        </div>
        </>
    );
}

export default Contact;